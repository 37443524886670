<template>
    <v-container fluid>
        <v-form @submit.prevent="getProducts('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'product.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="1" sm="1" style="max-width: 50px">
                                <v-btn :to="{name: 'dashboard'}"  class="back-btn" link large>
                                    <v-icon>mdi-less-than</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-subheader class="headline">{{ $t('search_product') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'product.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="product" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="product" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-shopping"
                                                  :label="$t('product_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="10">
                                <ValidationProvider ref="category"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="category" :items="itemCategory"
                                              :error-messages="errors" :disabled="loadingCategory" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-folder-outline" :label="$t('category_product')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="productItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalProducts" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="showProduct(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteProduct(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import {mask} from 'vue-the-mask'
    import debounce from "lodash/debounce";

    export default {
        name: "Products",
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                sortBy: "id",
                sortDir: false,
                loadingCategory: false,
                category: null,
                itemCategory: [],
                loadingShop: false,
                loading: false,
                product: null,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalProducts: 0,
                productItems: [],
                active: 1,
                activeItems: [
                    {
                        id: null,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 0,
                        name: this.$t('no')
                    }
                ],
                headers: [
                    {
                        text: this.$t('price'),
                        align: "left",
                        sortable: true,
                        value: "price"
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('category_product'),
                        align: "left",
                        sortable: false,
                        value: "category"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active",
                        width: 120,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getCategories();
        },
        watch: {
            options: {
                handler() {
                    this.getProducts()
                },
                deep: false
            },
            shop() {
                if (this.shop) {
                    this.getCategories()
                }
            },
        },
        methods: {
            clearShop(){
                this.shop = null;
                this.getCategories()
            },
            showProduct(item) {
                this.$router.push({
                    name: 'product.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getProducts(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.product) {
                    params.product = this.product;
                }

                if (this.shop) {
                    if (this.shop.id) {
                        params.shop = this.shop.id;
                    } else {
                        params.shop = this.shop;
                    }
                }
                if (this.category) {
                    if (this.category.id) {
                        params.category = this.category.id;
                    } else {
                        params.category = this.category;
                    }
                }
                params.active = this.active
                await this.$http
                    .get("partner/product", {
                        params: params,
                    })
                    .then(res => {
                        this.productItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalProducts = res.body.meta.total
                        this.pageCount = res.body.meta.last_page

                    })
                    .catch(err => {
                        this.productItems = []
                        this.totalProducts = 0
                        this.$toastr.error(this.$t('failed_to_get_list_products'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async deleteProduct(item) {
                if (confirm(this.$t('delete_product'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`partner/product/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('product_has_been_deleted'))
                            this.getProducts()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('product_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },

            async getCategories() {
                this.loadingCategory = true;
                let params = {}

                if (this.shop) {
                    if (this.shop.category_shop_id) {
                        params.category_shop = this.shop.category_shop_id
                    } else {
                        params.category_shop = this.category_shop_id
                    }
                }

                params.filter = 'search';
                await this.$http
                    .get(`partner/category`, {params: params})
                    .then(res => {
                        this.itemCategory = res.body.data
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('messages.failed_to_get_list_categories'));
                        this.itemCategory = []

                    })
                    .finally(end => {
                        this.loadingCategory = false
                    });
            },
        }
    }
</script>
